import { createGetKcContext } from "keycloakify/account";

type TotpData = {
  enabled: boolean;
  otpCredentials: OtpCredential[];
  supportedApplications: string[];
  totpSecretEncoded: string;
  qrUrl: string;
  policy: TotpPolicy;
  totpSecretQrCode: string;
  totpSecret: string;
  manualUrl: string;
};

type OtpCredential = {
  id: string;
  userLabel?: string;
  createdDate?: string;
};

type TotpPolicy = {
  type: string;
  getAlgorithmKey: () => string;
  digits: number;
  period: number;
  initialCounter: number;
  algorithm: string;
};

export type KcContextExtension =
  | {
      pageId: "account.ftl";
      account: {
        email: string;
        firstName: string;
        lastName: string;
        attributes: Record<string, string>;
      };
    }
  | {
      pageId: "totp.ftl";
      totp: TotpData;
      stateChecker: string;
      actionUrl: string;
      totpSecretEncoded: string;
      qrUrl: string;
      otpCredentials: OtpCredential[];
      supportedApplications: string[];
      policy: TotpPolicy;
      mode: "manual" | "app";
      client: {
        clientId: string;
        baseUrl: string;
      };
      realm: {
        name: string;
      };
      recoveryAuthnCodesConfigBean: {
        generatedRecoveryAuthnCodesList: string[];
      };
    }
  | { pageId: "my-extra-page-1.ftl" }
  | { pageId: "my-extra-page-2.ftl"; someCustomValue: string };

export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: "account.ftl",
      account: {
        username: "brummer",
        email: "brummer@equinux.com",
        firstName: "",
        lastName: "Brümmer",
        attributes: {
          personStreet: "Musterstraße 123",
          personStreet2: "2. Stock",
          personZip: "12345",
          personCity: "Musterstadt",
          personCompany: "Musterfirma",
          personTitle: "IT-Admin",
          personPhone: "+49 123 4567890",
        },
      },
      // Simulate we got an error with the firstName field
      messagesPerField: {
        printIfExists: <T>(fieldName: string, className: T) => {
          return fieldName === "firstName" ? className : undefined;
        },
        existsError: (fieldName: string) => fieldName === "firstName",
        get: (fieldName: string) => `Fake error for ${fieldName}`,
        exists: (fieldName: string) => fieldName === "firstName",
      },
      message: {
        type: "error",
        summary: "Error 1<br>Error 2",
      },
    },
    {
      pageId: "my-extra-page-2.ftl",
      someCustomValue: "foo bar",
    },
    {
      pageId: "totp.ftl",
      mode: "app",
      totp: {
        enabled: true,
        otpCredentials: [
          {
            id: "1",
            userLabel: "",
            createdDate: "1.687.971.288.176",
          },
          {
            id: "2",
            userLabel: "test1234",
            createdDate: "1.697.971.288.176",
          },
        ],
        supportedApplications: [
          "totpAppMicrosoftAuthenticatorName",
          "totpAppGoogleName",
        ],
        totpSecretEncoded: "INCW QYTS ONBG S2KU IRWX A6BU GBXG 4R2B",
        qrUrl: "#?qr",
        policy: {
          type: "totp",
          getAlgorithmKey: () => "INCW QYTS ONBG S2KU IRWX A6BU GBXG 4R2B",
          digits: 6,
          period: 30,
          initialCounter: 0,
          algorithm: "SHA1",
        },
        totpSecretQrCode:
          "iVBORw0KGgoAAAANSUhEUgAAAPYAAAD2AQAAAADNaUdlAAACoUlEQVR4Xu2YMY6DMBBFJ0pByRG4SbgYEpG4WLgJR6BMgXb2vzERCcU2W3iKuGCJn1eyx3/G35j/2TY793y2Lz/3fLYvP/d8NvhqZhf3RT/vnf/YYP5oN3XamIOP7vN6XRqhzm7t3Rpfhy5ACj5YA/fZJw1q3Z8Xcbtk4j65pr51/tAi4pGLr9YpqtY9ie/z4lsiXvbfJdJNKJTASk76qMfJH224FvH2OOVXRR5tHRcnvxXfPbWjZeCkjgpO11B1/Ke9d2S66WcSbhYbrl4rpSceWkkOrqqj+VMVVcQbgszUl6cl4WR1rzepcqQ0Em7iy88MnPnP6JOtt16V8tkrf9BsDq5aIyRpsv9RKR/kzwxKwKVFvatq92WkuotmX/WpMhdSVEOQqj9m10XxvfqRP5X5rsoIaCgBNOXhTP1mJutlkTra/5lKfnnpszbXqUxoY+rWh5NQTuXhMoQ91prUwUQoyaVZPXJw/INREDn1jEhr/htKTcKF1BGDcIX6y/xDuBl4Ca1cIf5hL0IMPvK/NjfyB5M4UnrMit1Bril4yZUlCrYZ/h8RRCXPwDEMaovQRH4P3J9YiefgxX/F1WRg1hPl3KSEI75VeVh/9ClVUn/u5A9O9rW+yjzOEv3ZRcobU3/P77qcq7vJMFhLVZS1lp2QHcOEZeCkzkOhfd3aw0kk4nqoFkqk7H8Z1BPzWxKu8yUShiLuIVJMtpx2/Gt9Hg3DKnUWExH+P750ZeArWaNcIbQMnxiuk/q4f1bmYwiybLhKDyc18z/0W5lzF/a4Hw/oc6/fcRym4UpyArrEm5aDf/A9vhk4HXyf1kk4Ee5psbf8rsvZfwSpraeSGyY7Pjfs86/NI3+w/m25f2IS375v1eZ/tS8/93y2Lz/3fLZ/818mSF983H8r1wAAAABJRU5ErkJggg==",
        totpSecret: "INCW QYTS ONBG S2KU IRWX A6BU GBXG 4R2B",
        manualUrl: "#?manual",
      },
      recoveryAuthnCodesConfigBean: {
        generatedRecoveryAuthnCodesList: [
          "1.687.971.288.176",
          "1.697.971.288.176",
        ],
      },
      client: {
        clientId: "account",
        baseUrl: "http://localhost:8080/auth",
      },
      realm: {
        name: "master",
      },
    },
  ],
});

export const { kcContext } = getKcContext({
  mockPageId: "account.ftl",
  // mockPageId: "totp.ftl",
  // mockPageId: "password.ftl",
  // mockPageId: "select-authenticator.ftl",
});

export type KcContext = NonNullable<
  ReturnType<typeof getKcContext>["kcContext"]
>;
